/* eslint-disable react/no-array-index-key */
import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import Step from "./components/step/step";
import { isCenteredStyle, stepperStyle } from "./stepper.module.scss";

// Todo: Test component and write abstract function for the template level to test stepper.

const Stepper = ({ length, textArray, activeStep, isComplete, isCentered }) => {
  const hasText = textArray.length > 0;
  const stepperDynamicClassName = classnames(stepperStyle, {
    [isCenteredStyle]: isCentered,
  });
  return (
    <div className={stepperDynamicClassName}>
      {[...Array(length)].map((_, index) => {
        if (hasText) {
          return (
            <Step
              index={index}
              isActiveStep={index === activeStep - 1}
              isStepComplete={index < activeStep - 1}
              isComplete={isComplete}
              key={index}
              text={textArray[index]}
            />
          );
        }

        return (
          <Step
            index={index}
            isActiveStep={index === activeStep - 1}
            isStepComplete={index < activeStep - 1}
            isLastStep={index + 1 === length}
            key={index}
          />
        );
      })}
    </div>
  );
};

Stepper.defaultProps = {
  length: 0,
  activeStep: 1,
  textArray: [],
  isComplete: false,
  isCentered: false,
};

Stepper.propTypes = {
  length: PropTypes.number,
  activeStep: PropTypes.number,
  textArray: PropTypes.arrayOf(PropTypes.string),
  isComplete: PropTypes.bool,
  isCentered: PropTypes.bool,
};

export default Stepper;
