import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import SVGBackgroundHeroWrapper from "~components/atoms/svg_background_image/svg_background_image";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import Stepper from "~components/molecules/stepper/stepper";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import GreenhouseJobApplicationForm from "./components/greenhouse_job_application_form/greenhouse_job_application_form";
import {
  applicationWrapperStyle,
  wrapperStyle,
} from "./template_job_application.module.scss";

const H2_TEXT = "We promise this won't take long";
const H3_TEXT = "Your personal information";
const JOB_DESCRIPTION_LINK_TEXT = "Back to job description";
const HEADING_MARGIN = { marginTop: "lg" };

export default function TemplateJobApplication(props) {
  const { pageContext } = props;

  const { applicationSteps, subNavData, publicJobId, title, parentPagePath } =
    pageContext;

  const jobDescriptionLink = createInternalLink(parentPagePath);

  return (
    <TemplateGlobalEntry
      {...props}
      subNavData={subNavData}
      headerColor="blue"
      programmaticPageTitle={title}
    >
      <div className={wrapperStyle}>
        <SVGBackgroundHeroWrapper hasWave background="careers_secondary">
          <Contain extraSmall isTextCentered>
            <Heading isCentered visualLevel={2} seoLevel={1}>
              {title}
            </Heading>
            <ButtonTertiary
              color="yellow"
              leadingIcon="icon_chevron_left"
              isInteractive
              isFontBold
              isUnderlined
              isUppercase
              isCentered
              link={jobDescriptionLink}
              title={JOB_DESCRIPTION_LINK_TEXT}
            />
          </Contain>
        </SVGBackgroundHeroWrapper>
        <Contain extraSmall>
          <div className={applicationWrapperStyle}>
            <Stepper
              length={3}
              activeStep={2}
              textArray={applicationSteps}
              isCentered
            />
            <HorizontalDivider />
            <Heading
              balanceText
              margin={HEADING_MARGIN}
              isCentered
              visualLevel={4}
              seoLevel={2}
            >
              {H2_TEXT}
            </Heading>
            <Heading isCentered visualLevel={5} seoLevel={3}>
              {H3_TEXT}
            </Heading>
            <GreenhouseJobApplicationForm {...{ publicJobId }} />
          </div>
        </Contain>
      </div>
    </TemplateGlobalEntry>
  );
}

TemplateJobApplication.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    applicationSteps: PropTypes.arrayOf(PropTypes.string),
    publicJobId: PropTypes.number,
    title: PropTypes.string,
    language: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
    parentPagePath: PropTypes.string.isRequired,
  }),
};

TemplateJobApplication.defaultProps = {
  pageContext: {
    publicJobId: null,
    title: null,
    language: "en",
    subNavData: [],
    applicationSteps: [],
  },
};

export const query = graphql`
  query jobApplicationPageQuery($id: String, $langRegex: String) {
    ...jobListPageStory
    ...global
  }
`;
