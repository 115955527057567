import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import {
  iconCheckmarkStyle,
  isActiveStyle,
  isActiveTextStyle,
  isCompleteStyle,
  isStepCompleteStyle,
  stepIconStyle,
  stepStyle,
  textStyle,
} from "./step.module.scss";

function Step({ isActiveStep, index, isStepComplete, text, isComplete }) {
  const stepIconDynamicClassName = classnames(stepIconStyle, {
    [isActiveStyle]: isActiveStep,
    [isCompleteStyle]: isComplete,
    [isStepCompleteStyle]: isStepComplete,
  });

  const textDynamicClassName = classnames(textStyle, {
    [isActiveTextStyle]: isActiveStep,
  });

  return (
    <div className={stepStyle}>
      <div className={stepIconDynamicClassName}>
        {isStepComplete || (isActiveStep && isComplete) ? (
          <svg className={iconCheckmarkStyle}>
            <use xlinkHref="/images/icon_checkmark.svg#icon" />
          </svg>
        ) : (
          index + 1
        )}
      </div>
      {text && <span className={textDynamicClassName}>{text}</span>}
    </div>
  );
}

Step.propTypes = {
  isActiveStep: PropTypes.bool,
  isStepComplete: PropTypes.bool,
  isComplete: PropTypes.bool,
  index: PropTypes.number,
  text: PropTypes.string,
};

Step.defaultProps = {
  isActiveStep: false,
  isStepComplete: false,
  isComplete: false,
  index: null,
  text: "",
};

export default Step;
