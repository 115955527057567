import React, { useEffect } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import LoadingIndicator from "~components/atoms/loading_indicator/loading_indicator";
import useGreenhouseJobApplicationForm from "../../hooks/use_greenhouse_job_application_form/use_greenhouse_job_application_form";

const IFRAME_ID = "grnhse_app";

const handleSubmission = (e) => {
  if (typeof window !== "undefined") {
    if (
      e.origin === window?.Grnhse?.Settings?.targetDomain &&
      e.data === "greenhouse.confirmation"
    ) {
      // ToDo: [Tech debt] [Careers hub] Avoid hard coding applied link
      navigate("/jobs/applied");
    }
  }
};

function GreenhouseJobApplicationForm({ publicJobId, trackingId }) {
  const [isLoaded] = useGreenhouseJobApplicationForm({
    publicJobId,
    trackingId,
    lang: "en-gb",
    iframeId: IFRAME_ID,
  });
  useEffect(() => {
    window.addEventListener("message", handleSubmission, false);
    return () => {
      return window.removeEventListener("message", handleSubmission);
    };
  }, []);

  return (
    <div data-testid="greenhouse-job-application-form">
      {isLoaded ? <div id={IFRAME_ID} /> : <LoadingIndicator />}
    </div>
  );
}

GreenhouseJobApplicationForm.propTypes = {
  publicJobId: PropTypes.number,
  trackingId: PropTypes.number,
};

GreenhouseJobApplicationForm.defaultProps = {
  publicJobId: null,
  trackingId: null,
};

export default GreenhouseJobApplicationForm;
