import { ensureTrailingSlash } from "~utils/url_utils/ensureTrailingSlash/ensureTrailingSlash";

/* eslint-disable import/prefer-default-export */
export const createInternalLink = (path, state) => {
  const sanitisedPath = ensureTrailingSlash(path);
  const linkProps = {
    linktype: "story",
    story: {
      url: sanitisedPath,
    },
  };

  if (state) {
    linkProps.state = state;
  }

  return linkProps;
};
